<script setup lang="ts">
import {
	BaseSpinner,
	useCatalog,
	CatalogFooter,
	CatalogInner,
	BaseDatePicker,
	BaseSelect,
} from '@legacy-studio/library';
import { catalog } from '../core/application';
import {
	useTransactionsStatuses,
	useTransactionsTypes,
} from '../infrastructure/store';
import { COL_NAMES } from '../constants';
import type { Filters, TableCols } from '../types';
import Table from './Table.vue';

const {
	initialFetch,
	isLoading,
	initialFetchPending,
	limitModel,
	pageModel,
	total: pagesCount,
	getFilterModel,
	refreshData,
} = useCatalog<Filters, TableCols>(catalog, COL_NAMES);

const statusesModel = computed({
	get() {
		return getFilterModel.value.status;
	},
	set(value) {
		getFilterModel.value.status = value;
		refreshData();
	},
});

const typesModel = computed({
	get() {
		return getFilterModel.value.type;
	},
	set(value) {
		getFilterModel.value.type = value;
		refreshData();
	},
});

const dateModel = computed({
	get() {
		return getFilterModel.value.date;
	},
	set(value) {
		getFilterModel.value.date = value;
		refreshData();
	},
});

const statuses = useTransactionsStatuses();
const types = useTransactionsTypes();

await initialFetch();
</script>

<template>
	<BaseSpinner v-if="initialFetchPending" />

	<section
		v-else
		class="w-full bg-gray-800 py-3 xl:p-0 xl:pt-4"
	>
		<div class="flex flex-col justify-center gap-8">
			<div
				class="grid md:grid-cols-3 items-center md:justify-center gap-4 max-w-[760px] mx-auto w-full"
			>
				<BaseDatePicker
					v-model="dateModel"
					with-presets
					placeholder="Выберите диапазон"
				/>

				<BaseSelect
					v-model="statusesModel"
					:items="statuses"
				/>

				<BaseSelect
					v-model="typesModel"
					:items="types"
				/>
			</div>

			<CatalogInner :pending="isLoading">
				<Table />
			</CatalogInner>
			<CatalogFooter
				v-if="pagesCount > 1"
				v-model:limit="limitModel"
				v-model:page="pageModel"
				:pages-count="pagesCount"
			/>
		</div>
	</section>
</template>
